import React, { Component } from "react";

var xhr;

class AppVersion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            version: "..."
        };

        this.processRequest = this.processRequest.bind(this);
    }

    componentDidMount() {
        xhr = new XMLHttpRequest();
        //TODO: relative path
        xhr.open("GET", "http://localhost:8080/api/status", true);
        xhr.send();

        xhr.addEventListener("readystatechange", this.processRequest, false);
    }

    processRequest() {
        if (xhr.readyState === 4 && xhr.status === 200) {
            let response = JSON.parse(xhr.responseText);

            this.setState({
                version: response.version
            });
        }
    }

    render() {
        return (
            <p><strong>App Version: </strong>{this.state.version}</p>
        )
    }
}

export default AppVersion;